import './App.css';

function App() {
  const todayDate = new Date().toISOString().split('T')[0];

  return (
    <div className="App">
      <h1>Privacy Policy for Kasoa Market App</h1>

      <p>Last updated: {todayDate}</p>

      <p>
        Welcome to Kasoa Market App. This Privacy Policy describes how your
        information is collected, used, and shared when you use our app.
      </p>

      <h2>Information We Collect</h2>

      <p>
        We collect information that you provide when using our app, including
        but not limited to business registration data and revenue-related
        information.
      </p>

      <h2>How We Use Your Information</h2>

      <p>
        We use the information collected to facilitate business registration,
        process revenue collection, and improve our services. Your data may also
        be used for communication purposes related to the app.
      </p>

      <h2>Sharing Your Information</h2>

      <p>
        We may share your information with third-party service providers for
        the purpose of providing and improving our services. However, we do not
        sell or share your information with third parties for marketing
        purposes.
      </p>

      <h2>Data Security</h2>

      <p>
        We implement reasonable security measures to protect your information
        from unauthorized access and disclosure. However, no method of
        transmission over the internet or electronic storage is 100% secure, and
        we cannot guarantee absolute security.
      </p>

      <h2>Changes to This Privacy Policy</h2>

      <p>
        We may update our Privacy Policy from time to time. Any changes will be
        posted on this page with an updated date.
      </p>

      <h2>Contact Us</h2>

      <p>
        If you have any questions or concerns about our Privacy Policy, please
        contact us at <a href="mailto:codecoastech@gmail.com">codecoastech@gmail.com</a>.
      </p>

      <p>
        By using the Kasoa Market App, you agree to the terms outlined in this
        Privacy Policy.
      </p>
    </div>
  );
}

export default App;

